import React from "react";

function OurClient() {
  return (
    <div>
      <div className="relative overflow-hidden pt-4 ">
        <svg
          className="absolute -bottom-20 start-1/2 w-[1900px] transform -translate-x-1/2"
          width="2745"
          height="488"
          viewBox="0 0 2745 488"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 330.864C232.505 403.801 853.749 527.683 1482.69 439.719C2111.63 351.756 2585.54 434.588 2743.87 487"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 308.873C232.505 381.81 853.749 505.692 1482.69 417.728C2111.63 329.765 2585.54 412.597 2743.87 465.009"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 286.882C232.505 359.819 853.749 483.701 1482.69 395.738C2111.63 307.774 2585.54 390.606 2743.87 443.018"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 264.891C232.505 337.828 853.749 461.71 1482.69 373.747C2111.63 285.783 2585.54 368.615 2743.87 421.027"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 242.9C232.505 315.837 853.749 439.719 1482.69 351.756C2111.63 263.792 2585.54 346.624 2743.87 399.036"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 220.909C232.505 293.846 853.749 417.728 1482.69 329.765C2111.63 241.801 2585.54 324.633 2743.87 377.045"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 198.918C232.505 271.855 853.749 395.737 1482.69 307.774C2111.63 219.81 2585.54 302.642 2743.87 355.054"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 176.927C232.505 249.864 853.749 373.746 1482.69 285.783C2111.63 197.819 2585.54 280.651 2743.87 333.063"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 154.937C232.505 227.873 853.749 351.756 1482.69 263.792C2111.63 175.828 2585.54 258.661 2743.87 311.072"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 132.946C232.505 205.882 853.749 329.765 1482.69 241.801C2111.63 153.837 2585.54 236.67 2743.87 289.082"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 110.955C232.505 183.891 853.749 307.774 1482.69 219.81C2111.63 131.846 2585.54 214.679 2743.87 267.091"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 88.9639C232.505 161.901 853.749 285.783 1482.69 197.819C2111.63 109.855 2585.54 192.688 2743.87 245.1"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 66.9729C232.505 139.91 853.749 263.792 1482.69 175.828C2111.63 87.8643 2585.54 170.697 2743.87 223.109"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 44.9819C232.505 117.919 853.749 241.801 1482.69 153.837C2111.63 65.8733 2585.54 148.706 2743.87 201.118"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 22.991C232.505 95.9276 853.749 219.81 1482.69 131.846C2111.63 43.8824 2585.54 126.715 2743.87 179.127"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 1C232.505 73.9367 853.749 197.819 1482.69 109.855C2111.63 21.8914 2585.54 104.724 2743.87 157.136"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
        </svg>

        <div className="relative z-10">
          <div className="max-w-5xl px-4 xl:px-0 mx-auto">
            <div className="mb-4">
              <h2 className="text-neutral-400">
                Trusted by event planners, businesses, and over 99,000 satisfied
                clients like you
              </h2>
            </div>

            <div className="flex justify-between gap-6">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                viewBox="0 0 800.000000 800.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
                  className="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 text-neutral-400"
                  fill="currentColor"
                  stroke="none"
                >
                  <path
                    d="M1738 5579 c-9 -5 -59 -14 -110 -19 -51 -5 -107 -13 -124 -18 -55
            -17 -218 -84 -286 -117 -36 -19 -69 -31 -72 -28 -3 4 -6 1 -6 -6 0 -7 -4 -9
            -10 -6 -5 3 -10 2 -10 -3 0 -6 -11 -13 -25 -16 -22 -6 -44 -22 -99 -73 -13
            -12 -16 -13 -11 -3 10 19 -29 -13 -68 -55 -16 -16 -25 -23 -21 -15 4 8 -43
            -34 -105 -95 -61 -60 -111 -113 -111 -118 0 -4 142 -7 315 -7 l316 2 6 29 c3
            16 9 26 13 24 5 -3 12 5 15 17 7 24 120 199 163 253 13 17 76 83 139 148 64
            64 114 117 112 117 -2 0 -12 -5 -21 -11z"
                  />
                  <path
                    d="M2050 5584 c0 -4 48 -57 108 -118 106 -110 232 -266 232 -288 0 -6
            10 -25 23 -42 13 -17 26 -39 30 -48 8 -24 17 -23 57 7 18 14 49 28 67 31 18 3
            33 10 33 14 0 4 15 13 33 20 17 6 43 18 57 25 14 7 43 16 65 20 45 8 92 32
            100 52 2 8 0 11 -5 8 -6 -3 -10 -2 -10 3 0 21 -263 177 -281 167 -5 -4 -9 -1
            -9 6 0 7 -3 9 -7 6 -3 -4 -12 1 -18 10 -7 9 -26 18 -41 19 -51 3 -55 4 -49 14
            3 6 -1 7 -9 4 -8 -3 -29 1 -45 9 -17 8 -48 18 -71 22 -22 4 -38 11 -35 16 4 5
            -1 6 -9 3 -17 -7 -170 22 -198 37 -10 5 -18 7 -18 3z m460 -124 c8 -5 11 -10
            5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z"
                  />
                  <path
                    d="M1838 5434 c-35 -32 -65 -59 -68 -62 -3 -2 11 3 30 13 24 12 53 17
            95 15 113 -4 105 0 105 -46 0 -48 -9 -54 -78 -54 -46 0 -47 -1 -47 -30 0 -30
            0 -30 58 -30 l57 0 0 -40 0 -39 -62 -3 -63 -3 -3 -38 c-2 -20 0 -37 5 -36 4 1
            36 1 71 0 l62 -1 0 -40 0 -40 155 0 c85 0 155 4 155 9 0 5 -6 14 -13 20 -8 6
            -24 30 -36 53 -46 89 -208 280 -313 369 l-47 40 -63 -57z m171 -168 c7 -8 8
            -17 3 -20 -6 -3 -12 3 -15 14 -6 24 -4 25 12 6z"
                  />
                  <path
                    d="M1715 5323 c-80 -95 -240 -311 -232 -314 1 0 64 -2 140 -4 l137 -4 0
            185 c0 101 -1 184 -3 184 -1 0 -21 -21 -42 -47z"
                  />
                  <path
                    d="M3140 5298 l-85 -21 60 -54 60 -54 65 7 c85 9 99 8 146 -13 21 -9 42
            -16 46 -15 13 3 19 -62 16 -183 l-3 -120 49 0 50 0 23 60 c18 46 23 79 23 145
            -1 115 -25 164 -99 202 -28 14 -51 29 -49 31 2 3 -1 9 -6 14 -5 5 -6 3 -2 -4
            9 -16 11 -17 -57 7 -70 25 -128 24 -237 -2z"
                  />
                  <path
                    d="M2925 5101 c-34 -12 -45 -20 -35 -24 8 -4 4 -5 -9 -3 -13 3 -40 -3
            -60 -13 -20 -10 -56 -22 -79 -26 -33 -5 -41 -10 -32 -19 11 -11 400 -17 400
            -6 0 16 -101 110 -116 109 -11 0 -41 -8 -69 -18z"
                  />
                  <path
                    d="M529 4765 c-69 -127 -121 -264 -134 -355 -3 -24 -10 -49 -16 -56 -5
            -7 -12 -45 -15 -86 -3 -40 -6 -90 -8 -110 l-4 -38 365 0 c218 0 362 4 359 9
            -8 12 12 151 23 165 5 6 12 56 15 111 4 55 12 102 17 105 5 4 14 33 20 66 5
            32 16 73 24 89 7 17 16 49 20 72 3 23 13 47 21 54 8 6 14 21 14 33 0 21 0 21
            -329 21 l-329 0 -43 -80z"
                  />
                  <path
                    d="M1399 4828 c-17 -32 -68 -178 -63 -178 4 0 2 -6 -4 -12 -8 -10 -26
            -88 -23 -100 0 -2 -6 -25 -14 -52 -8 -27 -15 -75 -15 -107 0 -33 -5 -61 -12
            -65 -8 -5 -9 -2 -5 10 4 9 3 15 -2 12 -9 -6 -22 -46 -15 -46 3 0 59 42 126 93
            108 83 168 127 235 174 13 8 20 20 16 26 -3 6 -1 7 5 3 6 -4 39 13 73 36 83
            59 109 75 147 95 17 9 32 20 32 25 0 4 15 14 33 22 l32 15 -34 -6 c-24 -4 -32
            -3 -27 5 4 7 19 12 33 12 37 0 94 22 90 35 -2 6 -108 12 -301 15 -273 5 -299
            4 -307 -12z"
                  />
                  <path
                    d="M2338 4826 c-13 -14 -36 -26 -53 -28 -23 -2 -29 -7 -27 -22 2 -12 -1
            -17 -10 -14 -26 10 -57 -9 -54 -34 2 -17 -2 -24 -16 -25 -10 -1 -18 2 -18 8 0
            10 -33 12 -43 3 -10 -10 12 -44 29 -44 27 0 9 -25 -21 -28 -27 -3 -28 -2 -14
            14 9 11 9 15 2 10 -7 -4 -13 -2 -13 3 0 17 -40 13 -47 -4 -3 -9 -22 -24 -42
            -35 -20 -10 -30 -19 -23 -20 17 0 15 -17 -4 -24 -9 -4 -23 -1 -30 5 -11 9 -20
            6 -39 -15 -14 -15 -28 -25 -33 -23 -4 3 -13 -1 -20 -9 -21 -23 12 -29 70 -14
            37 9 53 9 61 1 8 -8 8 -11 -1 -11 -6 0 0 -11 13 -25 30 -29 32 -51 7 -66 -13
            -8 -17 -20 -14 -42 3 -20 1 -28 -7 -23 -6 4 -11 18 -11 31 0 22 -17 35 -46 35
            -11 0 -14 -28 -14 -155 l0 -155 319 0 319 0 -4 43 c-21 201 -76 463 -102 484
            -9 8 -11 12 -4 8 15 -8 15 -6 1 34 -6 17 -15 31 -21 31 -5 0 -6 5 -3 10 9 15
            -5 51 -17 44 -6 -4 -8 -3 -5 3 9 14 -15 73 -30 73 -7 0 -22 -11 -35 -24z m82
            -76 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
            m-220 -70 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
            -10z m30 -44 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
            -2 10 -4z m-56 -21 c-4 -8 -10 -15 -14 -15 -4 0 -10 7 -14 15 -4 10 1 15 14
            15 13 0 18 -5 14 -15z m76 -10 c0 -9 -6 -12 -16 -8 -13 5 -13 7 -2 14 17 11
            18 11 18 -6z m210 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6
            0 10 -7 10 -15z m-120 -25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
            10 6 0 11 -4 11 -10z m-291 -74 c11 -7 11 -10 0 -17 -8 -4 -19 -3 -26 3 -27
            22 -6 34 26 14z m11 -87 c0 -4 -7 -6 -15 -3 -10 4 -15 0 -15 -12 0 -13 -3 -15
            -11 -7 -8 8 -8 15 2 26 14 15 39 12 39 -4z m-30 -49 c0 -5 -2 -10 -4 -10 -3 0
            -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m116 -105 c-3 -8 0 -15 6 -15
            6 0 9 -3 5 -6 -10 -11 -26 6 -20 21 3 8 7 15 10 15 3 0 3 -7 -1 -15z m228 0
            c-4 -8 -12 -15 -20 -15 -8 0 -14 7 -14 15 0 8 9 15 20 15 13 0 18 -5 14 -15z
            m-205 -49 c15 -18 18 -18 176 -17 87 1 125 -2 115 -8 -10 -7 -2 -11 30 -14 25
            -2 -85 -3 -245 -3 -159 0 -246 2 -193 5 97 6 147 21 70 21 -50 0 -56 17 -9 23
            17 2 35 5 38 5 4 1 12 -4 18 -12z m316 -75 c26 -5 -22 -8 -133 -7 -96 0 -163
            4 -150 7 33 10 236 9 283 0z"
                  />
                  <path
                    d="M2560 4825 c0 -8 8 -22 18 -32 22 -21 46 -99 36 -115 -4 -7 -3 -8 4
            -4 7 4 13 1 13 -8 1 -9 2 -19 3 -23 2 -5 6 -29 10 -55 5 -27 12 -48 16 -48 5
            0 11 -24 15 -52 4 -29 15 -92 26 -140 10 -48 19 -119 19 -158 l0 -70 360 0
            360 0 0 43 c0 23 -7 71 -16 107 -8 36 -19 90 -25 120 -8 46 -33 129 -57 190
            -3 8 -7 20 -7 25 -1 6 -5 17 -8 25 -3 8 -9 26 -14 40 -4 14 -25 58 -46 98
            l-37 72 -335 0 c-287 0 -335 -2 -335 -15z"
                  />
                  <path
                    d="M3790 4468 c0 -90 -3 -180 -6 -200 -8 -45 -2 -47 144 -51 l102 -2 0
            33 0 32 -95 0 -95 0 0 60 0 60 59 0 c54 0 58 2 57 23 -1 20 -7 22 -58 25 l-58
            3 0 59 0 60 85 0 85 0 0 30 0 30 -110 0 -110 0 0 -162z"
                  />
                  <path
                    d="M4075 4600 c0 -29 2 -30 43 -30 l42 0 0 -180 0 -181 25 4 c43 5 40
            -10 43 220 l2 137 44 0 c41 0 44 2 48 30 l5 30 -126 0 -126 0 0 -30z"
                  />
                  <path
                    d="M4392 4423 c2 -126 7 -208 13 -210 28 -8 38 17 39 98 l1 84 72 3 72
            3 3 -93 c3 -92 3 -93 29 -96 25 -3 27 0 33 57 4 33 6 127 5 210 l-1 151 -34 0
            -34 0 0 -90 0 -90 -70 0 -70 0 0 90 0 90 -30 0 -30 0 2 -207z"
                  />
                  <path
                    d="M4730 4437 c0 -107 2 -201 6 -210 4 -11 17 -17 35 -17 l29 0 0 210 0
            210 -34 0 -34 0 -2 -193z"
                  />
                  <path
                    d="M4967 4616 c4 -5 -1 -7 -10 -3 -10 3 -17 2 -17 -3 0 -6 -3 -9 -7 -8
            -5 1 -18 -3 -29 -10 -20 -11 -23 -23 -29 -134 -8 -155 0 -195 45 -226 29 -20
            44 -23 94 -20 51 4 63 9 88 37 l29 33 -2 141 c-1 127 -4 144 -22 165 -11 12
            -31 22 -44 22 -13 0 -22 3 -20 8 3 4 -14 7 -38 7 -25 0 -41 -4 -38 -9z m88
            -71 c23 -22 25 -31 25 -125 0 -94 -2 -103 -25 -125 -28 -29 -61 -32 -93 -9
            -21 14 -22 23 -22 130 0 101 2 116 20 134 27 27 66 25 95 -5z"
                  />
                  <path
                    d="M1610 4365 c-63 -48 -113 -88 -110 -90 6 -5 -91 -81 -98 -77 -3 2 -8
            0 -11 -5 -3 -5 -23 -19 -43 -32 -21 -12 -38 -27 -38 -32 0 -5 110 -9 263 -9
            l262 0 -3 153 -3 152 -44 -3 c-36 -3 -45 -1 -45 12 0 9 -3 16 -8 16 -4 0 -59
            -38 -122 -85z m-45 -96 c-6 -18 -5 -19 13 -10 27 14 52 14 52 0 0 -6 -7 -9
            -15 -5 -8 3 -15 1 -15 -4 0 -6 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 9 -10
            21 -10 11 0 17 5 14 10 -3 6 3 10 14 10 14 0 21 -6 21 -20 0 -26 8 -29 105
            -39 68 -8 52 -9 -103 -8 -174 1 -224 6 -187 21 9 3 21 3 26 0 5 -3 8 -1 7 4
            -2 8 69 89 80 91 2 1 0 -8 -3 -20z m183 -136 c-32 -2 -86 -2 -120 0 -35 2 -9
            3 57 3 66 0 94 -1 63 -3z"
                  />
                  <path
                    d="M355 3903 c12 -126 16 -147 25 -153 6 -4 14 -40 19 -82 5 -41 19 -99
            33 -129 l24 -53 24 39 c13 22 75 92 138 157 63 64 112 121 108 126 -7 11 18
            35 27 25 10 -10 38 19 33 33 -3 8 -2 13 2 11 9 -3 92 64 92 75 0 4 -119 8
            -265 8 l-265 0 5 -57z"
                  />
                  <path
                    d="M1244 3938 c3 -13 6 -47 6 -77 0 -46 2 -52 15 -41 12 10 13 7 5 -21
            -6 -18 -6 -35 -1 -38 7 -5 22 -119 23 -180 0 -18 2 -21 9 -10 5 8 9 10 9 4 1
            -35 21 -148 29 -163 5 -9 21 -52 36 -94 l27 -78 174 0 174 0 0 194 c0 212 -2
            206 61 206 l29 0 0 -80 0 -80 55 0 55 0 0 69 c0 77 11 94 57 89 l28 -3 6 -195
            c3 -107 6 -196 7 -197 1 -1 78 -3 171 -5 l170 -3 26 70 c15 39 33 85 41 103
            28 65 63 228 64 293 0 61 10 110 21 103 5 -3 9 21 9 53 0 32 3 68 6 81 l6 22
            -662 0 -662 0 6 -22z m1266 -39 c0 -19 -1 -19 -16 -4 -11 10 -22 13 -33 8 -9
            -4 -90 -10 -181 -13 l-165 -6 152 -2 c158 -2 183 -6 183 -27 0 -20 28 -26 32
            -7 3 11 6 8 10 -10 3 -19 1 -28 -7 -28 -7 0 -15 -17 -19 -39 -5 -33 -10 -39
            -37 -45 -60 -12 -69 -8 -69 24 0 17 5 30 11 30 6 0 9 7 5 15 -3 8 -1 15 4 15
            5 0 11 -8 13 -17 3 -13 5 -12 6 5 1 23 -1 23 -79 20 -76 -3 -80 -2 -80 18 0
            20 -7 21 -147 30 -136 8 -270 10 -432 5 -35 0 -79 4 -97 11 -22 8 -37 8 -46 1
            -18 -14 -98 -4 -98 12 0 7 5 15 11 17 7 2 252 5 545 6 534 2 534 2 534 -19z
            m-232 -152 c3 -24 7 -27 42 -27 29 0 42 -5 50 -20 10 -19 8 -20 -60 -20 l-70
            0 0 51 c0 43 3 50 18 47 10 -2 18 -14 20 -31z m-291 -83 c-3 -3 -12 -4 -19 -1
            -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-250 -201 c-2 -16 -4 -3 -4 27 0 30 2 43 4
            28 2 -16 2 -40 0 -55z m-222 -163 c4 -6 -12 -10 -42 -10 -35 0 -44 3 -33 10
            20 13 67 13 75 0z m723 -7 c-21 -2 -57 -2 -80 0 -24 2 -7 4 37 4 44 0 63 -2
            43 -4z"
                  />
                  <path
                    d="M2720 3898 c0 -85 -11 -149 -20 -123 -5 13 -10 -5 -14 -50 -7 -75
            -39 -225 -62 -290 -7 -22 -18 -64 -25 -92 -6 -29 -15 -53 -20 -53 -5 0 -9 -12
            -9 -27 l0 -28 132 2 c73 2 221 4 328 5 l195 3 35 70 c19 39 38 72 41 75 3 3 9
            19 13 35 4 17 18 58 32 92 35 90 94 334 94 392 l0 51 -360 0 -360 0 0 -62z"
                  />
                  <path
                    d="M857 3717 c-108 -111 -219 -231 -246 -267 -26 -36 -58 -74 -70 -84
            -26 -24 -27 -41 -1 -73 11 -14 20 -32 20 -39 0 -12 55 -14 313 -14 171 0 322
            -3 335 -6 19 -6 22 -3 22 25 0 18 -6 34 -14 37 -8 3 -18 24 -21 47 -4 23 -11
            48 -16 57 -12 20 -46 173 -64 286 -8 51 -19 104 -25 119 -5 14 -10 46 -10 71
            0 29 -4 44 -12 44 -7 -1 -102 -92 -211 -203z"
                  />
                  <path
                    d="M3772 3644 c5 -204 8 -217 49 -207 16 5 19 15 19 79 l0 74 60 0 c83
            0 116 19 131 72 17 64 6 118 -31 151 -29 26 -35 27 -132 27 l-101 0 5 -196z
            m189 130 c13 -8 11 -108 -3 -122 -7 -7 -36 -12 -65 -12 l-53 0 0 76 0 77 56
            -7 c31 -4 61 -9 65 -12z"
                  />
                  <path
                    d="M4103 3833 c-10 -3 -13 -53 -13 -198 l0 -193 27 -5 c16 -3 31 -3 35
            0 5 2 8 40 8 84 l0 80 27 -3 c22 -2 35 -16 65 -68 20 -36 37 -72 37 -80 1 -19
            44 -21 61 -4 12 12 -8 58 -55 128 -16 25 -17 29 -3 37 60 34 82 92 59 161 -17
            52 -54 68 -153 67 -45 0 -89 -3 -95 -6z m175 -65 c16 -16 15 -79 -2 -100 -10
            -13 -27 -18 -65 -18 l-51 0 0 65 0 65 53 0 c29 0 58 -5 65 -12z"
                  />
                  <path
                    d="M4469 3817 c-21 -14 -36 -34 -40 -52 -11 -50 -10 -250 2 -283 14 -42
            54 -56 136 -49 57 4 71 9 90 32 22 25 23 35 23 169 0 135 -1 144 -22 163 -56
            50 -132 58 -189 20z m139 -179 c3 -135 -4 -158 -49 -158 -23 0 -61 18 -71 34
            -14 20 -9 232 5 244 16 14 56 19 87 12 25 -6 25 -7 28 -132z m19 -30 c-3 -7
            -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"
                  />
                  <path
                    d="M4755 3646 c-4 -107 -5 -199 -1 -204 3 -5 15 -8 28 -6 13 1 27 3 31
            3 5 1 6 61 2 134 -5 119 -5 130 9 112 8 -11 23 -28 33 -38 9 -10 22 -32 28
            -50 16 -52 41 -46 69 15 13 29 32 58 43 65 19 11 20 8 15 -118 l-5 -129 37 0
            36 0 -2 203 -3 202 -30 3 c-32 3 -44 -12 -85 -98 -10 -19 -26 -43 -38 -53 -20
            -18 -21 -18 -31 2 -6 11 -11 24 -11 30 0 13 -16 49 -38 86 -13 21 -28 31 -49
            33 l-31 3 -7 -195z"
                  />
                  <path
                    d="M5225 3830 c-11 -4 -31 -19 -44 -34 -22 -23 -24 -36 -29 -143 -6
            -156 5 -195 58 -217 22 -9 54 -16 70 -16 46 0 106 28 119 56 6 15 11 80 11
            158 0 128 -1 134 -25 160 -39 43 -107 58 -160 36z m115 -77 c23 -21 18 -227
            -6 -254 -21 -23 -71 -25 -96 -2 -16 14 -18 33 -18 139 l0 122 28 10 c32 12 71
            5 92 -15z"
                  />
                  <path
                    d="M5477 3833 c-4 -3 -7 -19 -7 -35 0 -27 2 -28 50 -28 l50 0 0 -170 0
            -170 30 0 30 0 0 170 0 170 43 0 c52 0 70 11 74 44 l4 26 -134 0 c-74 0 -137
            -3 -140 -7z"
                  />
                  <path
                    d="M6005 3827 c-64 -30 -69 -45 -73 -198 l-4 -139 38 -36 c35 -34 41
            -36 88 -31 129 13 130 15 134 174 5 157 -1 186 -38 213 -37 26 -106 34 -145
            17z m105 -67 c12 -8 15 -34 14 -135 0 -138 -3 -145 -59 -145 -59 1 -65 14 -65
            147 0 79 4 123 12 131 14 14 77 16 98 2z"
                  />
                  <path
                    d="M6274 3829 c-14 -5 -15 -8 -3 -8 13 -1 14 -9 6 -58 -14 -91 -20 -326
            -8 -318 6 3 11 0 11 -9 0 -8 6 -16 13 -19 23 -8 35 45 29 140 -3 50 -2 99 2
            109 8 21 17 8 91 -134 57 -110 67 -120 100 -102 l25 13 0 194 c0 148 -3 193
            -12 193 -7 0 -24 3 -36 7 l-24 6 4 -123 c2 -80 -1 -124 -7 -127 -6 -2 -37 50
            -69 116 -60 123 -75 138 -122 120z"
                  />
                  <path
                    d="M6683 3830 c-47 -15 -73 -54 -73 -109 0 -55 25 -85 101 -120 46 -22
            89 -55 89 -69 -1 -15 -28 -42 -53 -51 -30 -12 -79 0 -103 24 -22 22 -39 14
            -39 -20 0 -59 114 -97 184 -61 53 28 93 121 61 141 -6 4 -8 10 -6 14 11 17
            -29 53 -100 92 -77 42 -90 63 -58 90 18 14 81 17 126 4 21 -6 29 -3 34 10 17
            43 -87 78 -163 55z"
                  />
                  <path
                    d="M735 3820 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
            -8 -4 -11 -10z"
                  />
                  <path
                    d="M5797 3718 c-8 -140 -9 -256 -1 -285 6 -22 7 -22 35 -6 l29 17 0 193
            0 193 -28 0 -29 0 -6 -112z"
                  />
                  <path
                    d="M7090 3622 l0 -209 110 -2 110 -2 0 35 0 36 -77 0 -78 0 3 175 4 175
            -36 0 -36 0 0 -208z"
                  />
                  <path
                    d="M7370 3620 l0 -210 110 0 110 0 0 35 0 35 -80 0 -80 0 0 175 0 175
            -30 0 -30 0 0 -210z"
                  />
                  <path
                    d="M7715 3807 c-65 -30 -70 -45 -70 -194 l0 -135 39 -34 c46 -40 88 -44
            153 -15 42 20 73 61 73 100 0 26 -15 34 -46 27 -17 -5 -24 -13 -24 -31 0 -37
            -42 -61 -88 -49 l-37 9 -3 132 -3 131 34 11 c23 8 43 8 60 2 14 -5 27 -8 29
            -6 2 2 5 -7 6 -21 3 -36 10 -44 37 -44 39 0 45 66 8 99 -15 14 -16 14 -7 1 5
            -9 1 -8 -8 3 -23 24 -112 32 -153 14z"
                  />
                  <path
                    d="M6903 3472 c-9 -6 -6 -19 12 -49 16 -29 20 -43 12 -47 -7 -2 -5 -5 4
            -5 17 -1 29 36 29 85 0 18 -5 24 -22 24 -13 0 -28 -4 -35 -8z"
                  />
                  <path d="M1840 3320 l0 -80 55 0 55 0 0 80 0 80 -55 0 -55 0 0 -80z" />
                  <path
                    d="M305 3279 c-14 -28 -34 -63 -45 -76 -11 -14 -20 -30 -20 -35 0 -5
            -11 -33 -25 -61 -58 -125 -70 -243 -32 -329 22 -49 80 -108 105 -108 6 0 12
            -5 14 -12 3 -7 49 -13 141 -16 122 -4 140 -3 157 13 23 21 25 43 8 80 -11 24
            -14 25 -102 25 -77 0 -98 4 -136 24 -24 13 -47 30 -51 37 -3 8 -11 24 -17 36
            -14 27 -16 63 -3 63 5 0 12 20 15 44 4 24 25 78 46 119 22 42 40 84 40 92 0 9
            4 14 8 11 13 -8 8 42 -5 56 -7 6 -13 16 -13 20 0 12 -42 68 -51 68 -5 0 -19
            -23 -34 -51z"
                  />
                  <path
                    d="M690 3067 c0 -17 165 -176 254 -245 87 -68 251 -162 341 -197 39 -15
            86 -33 105 -40 32 -13 152 -45 168 -45 4 0 2 -4 -4 -8 -7 -4 20 -8 60 -9 83
            -2 119 -10 111 -23 -3 -6 1 -10 9 -10 9 0 16 3 16 6 0 4 -55 64 -122 133 -121
            124 -214 249 -289 388 l-34 63 -307 0 c-248 0 -308 -3 -308 -13z"
                  />
                  <path
                    d="M1480 3074 c0 -3 9 -18 20 -32 12 -15 55 -75 96 -134 102 -148 189
            -239 220 -234 13 3 24 0 24 -6 0 -5 -8 -7 -17 -4 -13 5 -12 1 6 -18 14 -15 29
            -22 39 -19 9 4 13 2 9 -4 -4 -6 0 -16 7 -22 12 -10 32 3 103 67 103 93 147
            144 231 267 34 50 71 102 82 118 l22 27 -421 0 c-232 0 -421 -3 -421 -6z m500
            -105 c-12 -9 -11 -9 5 -4 33 12 89 15 104 6 18 -12 6 -47 -19 -54 -11 -3 -20
            -1 -20 4 0 5 6 9 14 9 21 0 30 20 17 33 -9 9 -11 8 -6 -5 6 -14 2 -18 -15 -18
            -18 0 -21 -4 -18 -25 3 -14 2 -25 -1 -25 -4 0 -7 -16 -8 -36 -1 -20 -9 -44
            -18 -53 -10 -9 -15 -10 -11 -4 5 9 2 11 -8 7 -9 -3 -14 -9 -12 -13 2 -3 -12
            -7 -32 -7 -21 -1 -27 1 -14 3 28 6 42 31 35 66 -4 20 -11 27 -29 27 -22 0 -24
            4 -24 50 l0 50 38 0 c29 0 34 -3 22 -11z"
                  />
                  <path
                    d="M2483 3063 c-13 -34 -117 -199 -179 -283 -34 -47 -98 -120 -142 -163
            -98 -95 -107 -112 -55 -103 21 3 72 9 113 13 45 4 67 9 55 13 -15 5 -6 10 35
            20 214 51 517 226 677 392 28 29 67 66 87 82 19 16 33 32 31 37 -3 4 -143 8
            -310 9 -284 1 -306 -1 -312 -17z"
                  />
                  <path d="M1508 2533 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
                </g>
              </svg>


              <svg
                className="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 text-neutral-400"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2428 1002"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M311.5 389.8h191.8l67 117.5 77.8-117.5h178.3L682.7 590.7l154 220.7H648.1l-77.8-135.8-91.7 135.8h-175l153.2-220.7-145.3-200.9Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1279.3 640.7H955.4c2.9 26 10 45.2 21 58a76.5 76.5 0 0 0 61.1 27.3c16 0 31.5-4 45.3-12 8.8-5 18.2-13.7 28.2-26.5l159.2 14.7c-24.4 42.4-53.7 72.7-88 91.2-34.5 18.2-83.8 27.5-148.2 27.5-55.8 0-99.7-7.9-131.8-23.6a193.2 193.2 0 0 1-79.6-75c-21-34.4-31.6-74.6-31.6-121 0-65.8 21.2-119.2 63.3-159.8 42.3-40.8 100.6-61.3 175-61.3 60.3 0 108 9.2 142.8 27.5a184 184 0 0 1 79.8 79.3c18.3 34.7 27.4 79.8 27.4 135.3v18.4ZM1115 563.3c-3.2-31.3-11.6-53.7-25.2-67.1a73.1 73.1 0 0 0-53.8-20.3 73.6 73.6 0 0 0-61.6 30.6c-9.7 12.7-16 31.6-18.5 56.8H1115Zm137-173.5h168.3l81.9 267.1 84.5-267H1750l-179.1 421.5h-143.3L1252 389.8Zm463.2 212c0-64.3 21.7-117.4 65-159 43.5-41.7 102-62.6 176-62.6 84.4 0 148.2 24.5 191.3 73.5 34.6 39.4 52 88 52 145.8 0 64.7-21.5 117.8-64.5 159.3-43 41.3-102.4 62-178.5 62-67.7 0-122.5-17.1-164.3-51.5-51.4-42.6-77-98.4-77-167.6Zm162-.5c0 37.7 7.5 65.5 22.8 83.4a72 72 0 0 0 57.3 27.1c23.4 0 42.5-9 57.4-26.7 15-17.8 22.5-46 22.5-85.4 0-36.4-7.6-63.7-22.7-81.5a70.5 70.5 0 0 0-56-26.7c-23.5 0-43 9-58.3 27-15.4 18.2-23 45.9-23 82.8ZM2363.1.1a64 64 0 0 1 0 127.9 64 64 0 0 1 0-128Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1912.1 671.5c220.3-135 326.4-327 334-419.2 8.7-106.7-71-235.9-358.9-238-345 3.6-790 158.3-1163.6 360.4h138c315.8-152.6 672-266.2 1000.8-275.2 287.7-7.8 304.4 149.2 302 199-3.6 71-74.7 234.5-252.3 373Zm-1315.7-222-36 22.7 10 17.5 26-40.1ZM419.8 567.5C212 717 57 873.2.8 1001.9 77.8 897.1 217 771 394.9 647l40.4-58.1-15.5-21.4Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2036.3 580a819.8 819.8 0 0 0 114.2-122.8l-3-3.5c-8-9.2-17-17.5-26.5-25-21 39.8-50 83.7-88.2 128.3 1.6 7 2.8 14.7 3.5 23Z"
                  fill="currentColor"
                />
              </svg>

              <svg
                className="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 text-neutral-400"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 127 33"
              >
                <path
                  d="M9.3 16.5C9.3 9 14.3 2.7 21.2.7a16.5 16.5 0 1 0 0 31.6c-6.9-2-11.9-8.3-11.9-15.8Z"
                  fill="currentColor"
                />
                <path
                  d="M21.7 10c4 0 7.4 2.8 8.5 6.4a8.9 8.9 0 1 0-17 0c1-3.6 4.4-6.3 8.5-6.3Z"
                  fill="currentColor"
                />
                <path
                  d="M24.8 19.4c0 3-2 5.5-4.8 6.3A6.6 6.6 0 1 0 20 13c2.8.8 4.8 3.4 4.8 6.4Z"
                  fill="currentColor"
                />
                <path
                  d="M39.6 13.5A4.4 4.4 0 0 1 44 9.1h1.3v2.7l-1 .2-1 .6-.2.4-.1.5h2.3v3H43v9.2h-3.4v-9.3h-1.3v-2.9h1.3ZM55.7 13.5h3.4v6.1a6.9 6.9 0 0 1-1.7 4.6 6 6 0 0 1-4.5 1.8c-1 0-1.8-.1-2.5-.5a6 6 0 0 1-3.2-3.4c-.3-.8-.4-1.6-.4-2.5v-6H50v6c0 .5 0 1 .2 1.3l.5 1c.2.4.5.6.9.8.3.2.8.3 1.2.3a2.6 2.6 0 0 0 2.1-1c.3-.3.4-.7.5-1l.2-1.4v-6ZM61.2 25.7V9.5h3.4v16.2h-3.4ZM66.9 25.7V9.5h3.3v16.2H67ZM78.5 21.2l3.3-7.7h3.7l-5.7 12.2h-2.7l-5.6-12.2H75l3.4 7.7ZM87 13.5h3.3v12.2H87V13.5Zm1.6-5 .8.1.6.4.4.7.2.7a1.9 1.9 0 0 1-.6 1.4l-.6.4a2 2 0 0 1-.8.1c-.5 0-1-.2-1.3-.5a2 2 0 0 1-.4-2.1c0-.3.2-.5.4-.7l.6-.4.7-.1ZM98.8 13.2a6.7 6.7 0 0 1 4.8 1.9 6.3 6.3 0 0 1 1.9 5.7h-9.8a3.3 3.3 0 0 0 3.2 2.2c.5 0 1-.1 1.4-.4.5-.2.9-.5 1.2-1h3.7c-.2.7-.5 1.3-1 1.8a6.1 6.1 0 0 1-3.3 2.3 7 7 0 0 1-6.9-1.6 6.1 6.1 0 0 1-2-4.5 6.1 6.1 0 0 1 2-4.5c.7-.6 1.4-1 2.2-1.4.8-.3 1.7-.5 2.6-.5Zm3.2 5.2c-.3-.6-.7-1.1-1.2-1.5-.6-.4-1.3-.7-2-.7s-1.4.3-2 .7c-.5.4-.9.9-1.1 1.5h6.3ZM123 13.5h3.6l-5 12.2H119l-2.5-6.5-2.5 6.5h-2.7l-5-12.2h3.6l2.7 7 2.8-7h2.2l2.8 7 2.7-7Z"
                  fill="currentColor"
                />
              </svg>

              <svg
                className="py-3 lg:py-5 w-16 h-auto md:w-20 lg:w-24 text-neutral-400"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 88 22"
                enable-background="new 0 0 88 22"
              >
                <path
                  d="M36.3 14.6a7.3 7.3 0 0 1-5.6 2.8c-3.8 0-6.8-2.7-6.8-6.2a6 6 0 0 1 2-4.5A6 6 0 0 1 30.5 5c2.2 0 4.3 1 5.6 2.8l-2.5 1.8a3.7 3.7 0 0 0-3.1-1.8 3.5 3.5 0 0 0-3.5 3.5c.1 2 1.7 3.5 3.6 3.5 1.3 0 2.5-.6 3.2-1.7l2.5 1.5z"
                  fill="currentColor"
                />
                <path d="M37.7 0H40.8V17.1H37.7z" fill="currentColor" />
                <path
                  d="M49.1 14.7c2 0 3.7-1.6 3.8-3.6-.1-2-1.8-3.6-3.8-3.6s-3.7 1.6-3.8 3.6c.1 2 1.7 3.6 3.8 3.6m0-9.8c1.7-.1 3.4.5 4.7 1.7 1.3 1.2 2 2.8 2.1 4.5a6.4 6.4 0 0 1-2.1 4.5 6.4 6.4 0 0 1-4.7 1.7c-3.8 0-6.8-2.7-6.8-6.2s3-6.2 6.8-6.2"
                  fill="currentColor"
                />
                <path
                  d="M55.3 5.1 59 5.1 62 11.5 65.2 5.1 68.6 5.1 62 17.8z"
                  fill="currentColor"
                />
                <path
                  d="M77.5 9.4a3 3 0 0 0-2.9-1.9c-1.3 0-2.5.7-3.1 1.9h6zm2 6.3a7 7 0 0 1-4.6 1.6c-3.8 0-6.8-2.7-6.8-6.2 0-1.7.7-3.3 1.9-4.5a6 6 0 0 1 4.6-1.7c1.7-.1 3.3.6 4.5 1.8s1.8 2.8 1.7 4.5v.8h-9.6a3.9 3.9 0 0 0 6.5 1.5l1.8 2.2zm2.8-5.3c0-2.9 2.2-5.2 5.7-5.2V8c-.7 0-1.5.3-2 .8s-.7 1.3-.6 2v6.3h-3.1v-6.7z"
                  fill="currentColor"
                />
                <path
                  d="M9.7 5.6a5 5 0 0 0-8.3-3.5C0 3.5-.4 5.6.3 7.4s2.5 3 4.5 3h4.9V5.6zm1.4 0a5 5 0 0 1 8.3-3.5c1.4 1.4 1.8 3.5 1.1 5.3s-2.5 3-4.5 3h-4.9V5.6zm0 11a5 5 0 0 0 8.3 3.5c1.4-1.4 1.8-3.5 1.1-5.3s-2.5-3-4.5-3h-4.9v4.8zm-6.3 3.5c1.9 0 3.5-1.5 3.5-3.5v-3.5H4.8c-1.9 0-3.5 1.5-3.5 3.5s1.6 3.5 3.5 3.5zm4.9-3.5a5 5 0 0 1-8.3 3.5C0 18.7-.4 16.6.3 14.8s2.5-3 4.5-3h4.9v4.8z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* <OurClient/> */}
      </div>
    </div>
  );
}

export default OurClient;
