import React from 'react'
import BlogArticle from '../Components/Blog/BlogArticle'
import Footer from '../Components/Footer/Footer'

function BlogDetail() {
  return (
    <div>
      <BlogArticle/>
      <Footer />

    </div>
  )
}

export default BlogDetail
